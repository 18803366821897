import axios from 'axios'

import { IData } from './interface'
import './__mock__'

/** 查询商家个人信息（仅商家侧） */
export const apiGetData = async (): Promise<IData> => {
  return axios.get('/linkhub_oms/merchant/personal/info').then(({ $data }) => $data)
}

/** 更新商家信息（仅商家侧） */
export const apiEditUserInfo = async (reqData: { userName: string }) => {
  await axios.put('/linkhub_oms/user/info', reqData)
}

/** 新增or编辑公司信息（仅商家侧），有 id 则视为编辑 */
export const apiAddOrEditCompany = async (
  reqData: { companyName: string; address: string; dutyNumber: string; ioss: string },
  id?: number,
) => {
  await axios.post('/linkhub_oms/merchant/company', { ...reqData, id })
}

/** 删除公司信息（仅商家侧） */
export const apiDelCompany = async (id: number) => {
  await axios.delete(`/linkhub_oms/merchant/company/${id}`)
}

/** 新增or编辑银行卡（仅商家侧），有 id 则视为编辑 */
export const apiAddOrEditBank = async (
  reqData: { account: string; bankName: string; cardNumber: string },
  id?: number,
) => {
  await axios.post('/linkhub_oms/merchant/bank/card', { ...reqData, id })
}

/** 删除银行卡（仅商家侧） */
export const apiDelBank = async (id: number) => {
  await axios.delete(`/linkhub_oms/merchant/bank/card/${id}`)
}
