import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, FormProps, Input, message, Modal, Row } from 'antd'

import { formAutoTrimOnBlur } from '@/utils/utils'
import { apiEditUserInfo } from './apts'
import { IData } from './interface'
import { popupSlot } from './utils'

interface IFormValues {
  userName: string
}

interface IEditUserInfoModalProps extends Pick<IData, 'userName' | 'email'> {
  onSuccess?: (changedData: IFormValues) => void
}

const updatePopup = popupSlot.insert(null)

export const EditUserInfoModal = Object.assign(
  ({ userName, email, onSuccess, destroy }: IEditUserInfoModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const [loading, setLoading] = useState(false)

    const initialFormValues: IFormValues = { userName: userName || '' }
    const [form] = Form.useForm<IFormValues>()

    const formProps: FormProps<IFormValues> = {
      form,
      layout: 'vertical',
      initialValues: initialFormValues,
      onBlur: e => formAutoTrimOnBlur({ event: e, form }),
    }

    return (
      <Modal
        title={t('Setting.bian-ji-ge-ren-xin-xi')}
        width={400}
        onOk={async () => {
          try {
            setLoading(true)
            let values = await form.validateFields()
            values = form.getFieldsValue(true)
            await apiEditUserInfo(values)
            message.success(t('2-common.cao-zuo-cheng-gong'))
            onHide()
            onSuccess?.(values)
          } catch (err) {
            setLoading(false)
            throw err
          }
        }}
        okButtonProps={{ loading }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Form {...formProps} style={{ marginTop: 20 }}>
          <Row>
            <Col span={24}>
              <Form.Item label={t('Setting.yong-hu-ming')} name="userName" rules={[{ required: true }]}>
                <Input allowClear placeholder={t('2-common.qing-shu-ru')} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('2-common.you-xiang')}>
                <Input disabled value={email} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  },
  {
    open: (props: IEditUserInfoModalProps) => {
      updatePopup(<EditUserInfoModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
